<script>
import productService from '../../../services/productService';
import IconTeam from '../../../assets/svgs/icon-team.svg';
import IconArrow from '../../../assets/svgs/icon-arrow-right.svg';
import IconSearch from '../../../assets/svgs/icon-search.svg';
import { formatCurrency } from '../../../utils/currency';

export default {
  components: {
    IconTeam,
    IconArrow,
    IconSearch,
  },

  data: () => ({
    show: false,
    products: [],
    search: '',
    page: 1,
  }),

  async mounted() {
    this.products = await productService.getAll();
    this.products = this.products.map((item) => ({ ...item, status: item.status === 'A' ? 'Ativo' : 'Inativo' }));
  },

  computed: {
    head() {
      return [
        {
          prop: 'produtoId',
          label: 'Código',
          width: 119,
          sortable: true,
        },
        {
          prop: 'descricao',
          label: 'Descrição',
          width: 292,
          sortable: true,
        },
        {
          prop: 'unidade',
          label: 'Unidade',
          width: 200,
        },
        {
          prop: 'unidadeAbreviada',
          label: 'Unidade Abreviada',
          width: 200,
        },
        {
          prop: 'fatorDivisor',
          label: 'Fator Divisor',
        },
        {
          prop: 'status',
          label: 'Status',
          sortable: true,
        },
      ];
    },

    filtered() {
      const { products, search } = this;

      const lowered = search.toLowerCase();

      const items = products?.filter(({
        descricao, unidade, unidadeAbreviada,
      }) => (
        (descricao && descricao.toLowerCase().indexOf(lowered) !== -1)
        || (unidade && unidade.toLowerCase().indexOf(lowered) !== -1)
        || (unidadeAbreviada && unidadeAbreviada.toLowerCase().indexOf(lowered) !== -1)
      ));

      if (!search) {
        return products.map((item) => ({
          ...item,
          fatorDivisor: formatCurrency((parseFloat(item.fatorDivisor) * 100).toString()),
        }));
      }

      return items.map((item) => ({
        ...item,
        fatorDivisor: formatCurrency((parseFloat(item.fatorDivisor) * 100).toString()),
      }));
    },
  },

  methods: {
    toggle() {
      this.show = !this.show;
    },

    handleClick({ produtoId }) {
      this.$router.push({
        params: { produtoId },
        name: 'EditProduct',
      });
    },

    handlePage(page) {
      this.page = page;
    },

    handleGoPage(value) {
      const newPage = Number(value);

      const rest = this.products.length % 20 === 0 ? 0 : 1;

      const totalPages = Math.trunc(this.products.length / 20) + rest;

      if (newPage < 1) {
        this.page = 1;

        return;
      }

      if (newPage > totalPages) {
        this.page = totalPages;

        return;
      }

      this.page = newPage;
    },
  },
};
</script>

<template>
  <div class="team">
    <div class="team__head">
      <button
        class="icon-button is-filled"
        v-if="! show"
        @click.prevent="toggle"
      >
        <IconSearch/>
      </button>

      <el-input
        v-model="search"
        v-else
        placeholder="Buscar"
      >
        <template #suffix>
          <i
            class="el-input__icon el-icon-close"
            style="cursor: pointer;"
            @click.prevent="toggle"
          />
        </template>
      </el-input>

    </div>

    <div class="team__body">
      <div
        class="team__body-empty"
        v-if="products.length <= 0"
      >
        <span>
          <IconTeam/>
        </span>
        <h3>Ops...</h3>
        <p class="p1">Não há cultura(a)s para carregar.</p>
      </div>

      <div
        class="table"
        v-else
      >
        <el-table
          @row-click="handleClick"
          header-cell-class-name="team__header"
          :data="filtered.slice((page - 1) * 20, page * 20)"
        >
          <el-table-column
            v-for="item in head"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
          />
          <el-table-column
            align="left"
            width="56"
          >
            <IconArrow/>
          </el-table-column>

          <template #empty>
            <div class="team__body-empty">
              <span>
                <IconSearch/>
              </span>
              <h3>Nenhuma Cultura encontrada</h3>
              <p class="p1">Confira a grafia e busque outra vez, ou realize um novo cadastro.</p>
            </div>
          </template>
        </el-table>

        <div
          v-if="products.length > 20"
          class="team__table-footer"
        >
          <el-pagination
            :page-size="20"
            :current-page="page"
            hide-on-single-page
            :total="products.length"
            layout="prev, pager, next, jumper"
            @current-change="handlePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.team {
  &__head {
    width: fit-content;
    display: flex;
    margin-left: auto;

    button {
      margin-left: 24px;
    }

    .el-input {
      min-width: 316px;
    }
  }

  &__body {
    margin-top: 32px;

    &-empty {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      background-color: $--color-gray-1;
      padding: 64px;
      align-items: center;

      span {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 16px;
        justify-content: center;
        background-color: rgba($--color-info, 0.2);

        svg path {
          fill: $--color-info;
        }
      }

      h3 {
        color: $--color-gray-7;
      }

      & p {
        margin-top: 8px;
        color: $--color-gray-6;
        max-width: 272px;
      }
    }
  }

  &__header {
    .cell {
      padding: 0px 0px 0px 10px !important;
    }
  }

  &__table-footer {
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
}

  @media screen and (max-width: 1080px) {
    .team {
      padding: 0px 20px;
      box-sizing: border-box;
    }
  }
</style>
