export const SET_AUTHTOKEN = (state, authToken) => {
  state.authToken = authToken;
};
export const SET_USUARIO = (state, usuario) => {
  state.usuario = usuario;
};
export const DESTROY_TOKEN = (state) => {
  state.authToken = null;
  state.usuario = null;
};
export const SET_COMPANY = (state, company) => {
  state.company = company;
};
