import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import auth from './modules/auth'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth
  },
  getters,
  mutations,
  actions,
  state,
});
