/* eslint-disable @typescript-eslint/no-explicit-any */
import axios  from 'axios'
import baseService from './baseServise';
const baseURL = '/conta';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class userService extends baseService<any> {
  constructor(){
    super(baseURL);
  }

  async save (user, userId, companyId) {
    if (userId) {
      return axios.put(`${baseURL}/${userId}`, { ...user, usuarioId: userId, empresaId: companyId }).then((res) => res);
    }
    const newUser = { ...user, admin: true, empresaId: companyId };
    return axios.post(baseURL, newUser).then((res) => res);
  }

  async getAdminById (id:number | string): Promise<any> { 
    return axios.get(`${baseURL}/admin/${id}`).then(({data}) => data);
  }

}

export default new userService();
