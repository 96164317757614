import axios from 'axios';

const autenticacao = ({ commit }, form) => new Promise((resolve, reject) => {
  axios.post('/autenticacao/login/admin', {
    email: form.email,
    password: form.password,
  })
    .then(({ data }) => {
      const usuario = {
        id: data.usuarioId,
        nome: data.nome,
        email: data.email,
        empresaId: data.empresaId,
      };

      localStorage.setItem('auth_token', data.token);
      localStorage.setItem('usuario_admin', JSON.stringify(usuario));

      // axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      commit('SET_AUTHTOKEN', data.token);
      commit('SET_USUARIO', usuario);
      resolve(data);
    })
    .catch((error) => {
      console.log(error);
      localStorage.removeItem('auth_token');
      localStorage.removeItem('usuario_admin');
      commit('DESTROY_TOKEN');
      reject(error);
    });
});

const destroyToken = ({ commit }) => {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('usuario_admin');
  delete axios.defaults.headers.common.Authorization;
  commit('DESTROY_TOKEN');
};

export {
  autenticacao,
  destroyToken,
};
