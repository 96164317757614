import Methods from './utils/methods';
import {showNotify} from './utils/notificador';
import { MessageType } from './types/types'

export default {
  $clearArray: function (val) { return Methods.clearArray(val) },
  $showNotify: function (type: MessageType, message: string, title?: string) { return showNotify(type, message, title) },
}


