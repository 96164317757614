export const formatCurrency = (value) => {
  const formatValue = value
    .replace('.', '')
    .replace(',', '')
    .replace(/\D/g, '');
  const result = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
  }).format(parseFloat(formatValue) / 100);

  return result;
};
