import axios from 'axios'
import webStorage from '../common/webStorage'
import router from '../router'
import { IApiOKResult } from './api'
import { IUsuarioModel } from './usuario'

export class authService {
    logout() {
        webStorage.clean()
        router.push('/auth/login')
        window.location.reload()
    }

    async login({ email, password }) {
        const payload = {
            email,
            password
        }

        const { data } = (await axios.post<IApiOKResult<IUsuarioModel>>('/autenticacao/login/admin', payload)).data
        return data
    }
}

export default new authService();
