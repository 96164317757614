import Vue from 'vue';
import store from './store';
import VueRouter from 'vue-router';

import Auth from './layouts/auth.vue';
import Blank from './layouts/blank.vue';
import Navigate from './layouts/navigate.vue';
import Dashboard from './views/App/Dashboard.vue';
import AuthSignIn from './views/Auth/SignIn.vue';
import AuthSignInExternal from './views/Auth/SignInExternal.vue';
import AuthRecovery from './views/Auth/Recovery.vue';
import UserList from './views/App/User/List.vue';
import UserForm from './views/App/User/Form.vue';
import CompanyList from './views/App/Company/List.vue';
import CompanyForm from './views/App/Company/Form.vue';
import ProductList from './views/App/Product/List.vue';
import ProductForm from './views/App/Product/Form.vue';

/**
 * Install Vue Router.
 */
Vue.use(VueRouter);

/**
 * Auth routes.
 */
const auth = {
  path: '/auth/',
  redirect: '/auth/login',
  component: Auth,
  name: 'Login',
  children: [
    {
      path: 'login',
      component: AuthSignIn,
      meta: {
        requiresVisitor: true,
      },
    },
    {
      path: 'recovery',
      component: AuthRecovery,
      meta: {
        requiresVisitor: true,
      },
    },
    {
      path: 'external/:email/:pass?',
      name: 'External',
      component: AuthSignInExternal,
      meta: {
        requiresVisitor: true,
      },
    },
  ],
};

const user = {
  path: 'administradores',
  name: 'User',
  component: Blank,
  children: [
    {
      path: '',
      component: UserList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/administradores/novo',
      component: UserForm,
      name: 'NewUser',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/administradores/editar/:usuarioId?',
      component: UserForm,
      name: 'EditUser',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/administradores/user-master/:companyId?',
      component: UserForm,
      name: 'NewUserMaster',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

const company = {
  path: 'empresas',
  name: 'Company',
  component: Blank,
  children: [
    {
      path: '',
      name: 'ListCompanies',
      component: CompanyList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/empresas/novo',
      component: CompanyForm,
      name: 'NewCompany',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/empresas/editar/:empresaId?',
      component: CompanyForm,
      name: 'EditCompany',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

const product = {
  path: 'produtos',
  name: 'Product',
  component: Blank,
  children: [
    {
      path: '',
      component: ProductList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/produtos/novo',
      component: ProductForm,
      name: 'NewProduct',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/produtos/editar/:produtoId?',
      component: ProductForm,
      name: 'EditProduct',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};
/**
 * Main routes.
 */
const main = {
  path: '/',
  component: Navigate,
  children: [
    {
      path: '',
      redirect: '/auth/login',
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    user,
    company,
    product,
  ],
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  duplicateNavigationPolicy: 'ignore',
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    auth,
    main,
  ],
});

router.beforeEach((to, from, next) => {
  const authRequired = !!to.meta.requiresAuth
  const token = store.getters['auth/loginToken']

  document.title = 'Assinei CPR' + (to.name ? ` - ${to.name}` : '')

  if(authRequired && !token){
    next({ name: 'Login' });
  }else {
    if(token && to.path === '/auth/login'){
      next({ name: 'Dashboard' });
    } else {
      next();
    }
  }
});

export default router;
