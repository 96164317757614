import axios from 'axios';

export default abstract class baseService<T> {
  private baseURL: string;
  constructor(baseURL:string){
    this.baseURL = baseURL;
  }
  async getById (id:number | string): Promise<T> { 
    return axios.get(`${this.baseURL}/${id}`).then(({data}) => data);
  }

  async deleteById(id:number | string) {
    return axios.delete(`${this.baseURL}/${id}`).then(({data}) => data);
  } 
  
  async getAll (): Promise<Array<T>> {
    return  axios.get(`${this.baseURL}`).then(({data}) => data);
  } 
}
