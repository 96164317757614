import axios  from 'axios';
import { IUnidadeMedidaModel } from '~/types/cultura';
const baseURL = '/UnidadeMedida';

class undMedidaService {

  async getByProdutoId (id:number | string): Promise<IUnidadeMedidaModel> { 
    return axios.get(`${baseURL}?produtoId=${id}`).then(({data}) => data.data);
  }
  
  async getAll (): Promise<Array<IUnidadeMedidaModel>> {
    return  axios.get(`${baseURL}/obter-todas`).then(({data}) => data.data);
  } 

  async save (form:IUnidadeMedidaModel, unidadeMedidaId?:string) {
    if (unidadeMedidaId) {
      return axios.put(`${baseURL}/${unidadeMedidaId}`, form).then(({data}) => data.data);
    }
    return axios.post(baseURL, form).then(({data}) => data.data);
  }
}

export default new undMedidaService();