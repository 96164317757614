import axios from 'axios';
import authService from '../services/authService';
import webStorage from '../common/webStorage';

//  carrega as configurações antes de montar a aplicação
//  na esteira de deploy podemos configurar as variáveis
//  e marcar o JSON Transformation.

class ApiConfig {
  async set() {
    await fetch(process.env.BASE_URL + 'config/appsettings.json')
      .then((response) => response.json())
      .then((config) => {
        axios.defaults.baseURL = config.VUE_APP_API;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = config.VUE_APP_APP;
        axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
        //TODO: Pegar o tenant do usuário logado
        axios.defaults.headers.common['X-Tenant'] = 'fafca64f-a53b-478e-90b2-98f0e8e95cac'; 
        axios.interceptors.request.use(confg => {
          if (confg && webStorage.getItem('token')) {
            confg.headers.common.Authorization = `Bearer ${webStorage.getItem('token')}`
          }
          return confg
        }, error => {
          return Promise.reject(error)
        })

        axios.interceptors.response.use(response => {
          return response
        }, error => {
          const status = error.response.status

          switch (status) {
            case 400:
              return Promise.reject(error.response.data);
            case 401:
              authService.logout();
              break
            default:
              return Promise.reject(error.response);
          }
        })
      }
    );
  }
}

export default new ApiConfig();