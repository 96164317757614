<script>
import { mapGetters, mapMutations } from 'vuex';
import userService from '../../../services/userService';
import companyService from '../../../services/companyService';
import Label from '../../../components/Label.vue';
import IntModal from '../../../components/Modal.vue';
import IntInput from '../../../components/Input.vue';
import IconCheck from '../../../assets/svgs/icon-checked.svg';
import IconArrow from '../../../assets/svgs/icon-arrow-left.svg';

export default {
  components: {
    Label,
    IntModal,
    IntInput,
    IconCheck,
    IconArrow,
  },

  data: () => ({
    Id: null,
    form: {
      nome: '',
      email: '',
      telefone: '',
      tipo: 'Aprovador',
      regional: '',
      cargo: '',
      status: 'A',
      observacao: '',
    },
    feedback: false,
    feedbackError: false,
    errors: {
      submit: '',
    },
  }),

  methods: {
    ...mapMutations(['SET_COMPANY']),
    back() {
      this.$router.go(-1);
    },

    clear() {
      /**
       * @todo clear data
       */
      this.feedback = false;
      this.form = {
        nome: '',
        email: '',
        telefone: '',
        tipo: 'Aprovador',
        regional: '',
        cargo: '',
        status: 'A',
        observacao: '',
      };
      this.Id = null;
    },

    async submit() {
      try {
        const user = await userService.save(this.form, this.Id, this.companyId);
        if (this.getCompany) {
          await companyService.save(this.getCompany, this.companyId, user.Id);
          this.SET_COMPANY(null);
          if (!this.Id) {
            this.$router.push({ name: 'ListCompanies' });
          } else {
            this.$router.go(-1);
          }
        } else {
          this.feedback = true;
        }
      } catch (err) {
        console.log(err);
        this.feedbackError = true;
        this.errors.submit = 'Email existente em outro cadastro, verifique os dados e tente novamente.';
      }
    },
  },
  computed: {
    ...mapGetters(['getCompany']),
  },
  async mounted() {
    const { usuarioId, companyId } = this.$route.params;
    this.companyId = !companyId ? 1 : companyId;
    const user = await userService.getById(usuarioId).then((usuario) => usuario[0]);
    if (user) {
      this.form = user;
      this.Id = usuarioId;
    }
  },
};
</script>

<template>
  <div class="create-user">
    <div class="create-user__head">
      <RouterLink to="/administradores" class="p1">
        <IconArrow />Usuários
      </RouterLink>

      <h2>{{ this.Id ? "Editar" : "Novo" }} Administrador</h2>
    </div>

    <div class="create-user__body" id="identificacao">
      <form
        class="create-user__form"
        @submit.prevent="submit"
        enctype="multipart/form-data"
      >
        <div>
          <Label label="Identificação do administrador" />

          <!-- Informações básicas do cliente - CPF -->
          <div class="create-user__form-group">
            <!-- Nome -->
            <div class="create-user__form-item">
              <int-input
                label="Nome"
                v-model="form.nome"
                placeholder="Nome completo"
                maxlength="100"
              />
            </div>

            <!-- Número de inscrição -->
            <div
              :class="
                !this.Id
                  ? 'create-user__form-item is-multi'
                  : 'create-user__form-item'
              "
            >
              <int-input
                label="E-mail"
                v-model="form.email"
                placeholder="exemplo@email.com.br"
              />
            </div>

            <div class="create-user__form-item is-multi">
              <int-input
                label="Telefone"
                v-model="form.telefone"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />

              <int-input label="Cargo" v-model="form.cargo" />
            </div>
            <div class="create-user__form-item">
              <int-input
                inputType="textarea"
                v-model="form.observacao"
                label="Observações"
              />
            </div>
          </div>
          <div class="create-user__form-type">
            <p class="p2">Status:</p>

            <el-radio-group v-model="form.status">
              <el-radio label="A">Ativo</el-radio>
              <el-radio label="I">Inativo</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="create-user__form-actions">
          <el-button type="terciary" @click.prevent="back">
            Cancelar
          </el-button>

          <el-button type="primary" native-type="submit"> Salvar </el-button>
        </div>
      </form>
    </div>

    <int-modal :show="feedback">
      <div class="modal-feedback">
        <div class="modal-feedback__message">
          <span>
            <IconCheck />
          </span>
          <h2>
            {{
              this.id
                ? "Usuário editado com sucesso!"
                : "Usuário cadastrado com sucesso!"
            }}
          </h2>
        </div>

        <div class="modal-feedback__actions">
          <el-button
            :type="this.Id ? 'primary' : 'terciary'"
            @click.prevent="back"
          >
            Ok
          </el-button>

          <el-button v-if="!this.Id" type="primary" @click.prevent="clear">
            Cadastrar novo usuário
          </el-button>
        </div>
      </div>
    </int-modal>
    <IntModal
      :show="feedbackError"
      :draft-analyse="true"
    >
      <template v-slot:head>
        <div class="modal__custom-head">
          <IconDanger />
          <h2 class="modal__custom-head__danger">Atenção!</h2>
        </div>
      </template>
      <template v-slot:body>
        <div class="modal__custom-body">
          <p class="modal__custom-body__p2">
            {{errors.submit}}
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="modal__custom-footer">
          <el-button @click="feedbackError=false" type="danger"
            >Retornar
          </el-button>
        </div>
      </template>
    </IntModal>
  </div>
</template>

<style lang="scss">
.create-user {
  &__head {
    margin-bottom: -100px;
    display: flex;
    flex-direction: column;

    a {
      color: $--color-gray-6;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h2 {
      color: $--color-gray-7;
      margin-top: 4px;
    }
  }

  &__body {
    display: flex;
    margin-top: 36px;
  }

  &__form {
    width: 100%;
    margin: 100px 0 0 0;

    &-type {
      display: flex;
      margin-top: 10px;
      align-items: center;

      p {
        color: $--color-gray-6;
      }

      .el-radio-group {
        margin-left: 30px;
      }
    }

    &-group {
      margin-top: 48px;

      .upload {
        margin-top: 28px;
      }
    }

    &-item {
      &.is-multi {
        display: flex;
        align-items: flex-end;

        .group-input {
          width: 100%;

          & > .input:not(:first-child) {
            margin-top: 24px;
          }
        }

        & > .input:not(:first-child) {
          margin-left: 16px;
        }
      }

      &:not(:first-child) {
        margin-top: 28px;
      }
    }

    &-actions {
      display: flex;
      border-top: 1px solid $--color-gray-5;
      margin-top: 32px;
      padding-top: 32px;
      align-items: center;
      justify-content: flex-end;

      .el-button {
        margin-left: 24px !important;
      }
    }
  }

  .modal-feedback {
    display: flex;
    flex-direction: column;

    &__message {
      display: flex;
      align-items: center;

      span {
        height: 64px;
        display: flex;
        min-width: 64px;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        background-color: $--color-success-light-2;
      }

      h2 {
        color: $--color-success;
        margin-left: 16px;
      }
    }

    &__actions {
      display: flex;
      margin-top: 36px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
