import axios  from 'axios';
import baseService from './baseServise';
const baseURL = '/empresa';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class companyService extends baseService<any> {
  constructor(){
    super(baseURL);
  }

  async save (company, companyId, userId) {
    if (companyId) {
      company.usuarioMasterId = userId;
      return axios.put(`${baseURL}/${companyId}`, company).then(({data}) => data);
    }
    return axios.post(baseURL, company).then(({data}) => data);
  }
}

export default new companyService();
