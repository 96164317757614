import './styles/index.scss';
import './plugins';
import VueMce from 'vue-mce';
import Vue from 'vue';
import ElementUI from 'element-ui';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import locale from 'element-ui/lib/locale/lang/pt-br';
import App from './App.vue';
import store from './store';
import router from './router'; 
import ApiConfig from './config/apiConfig'; 
import methodsGlobal from './prototype';

Object.assign(Vue.prototype, methodsGlobal);

const pluginOptions = {
  globalOptions: {
    currency: null,
    distractionFree: false,
    valueAsInteger: true,
    autoDecimalMode: true,
  },
};
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(ElementUI, { locale });
Vue.use(VueTheMask);
Vue.use(VueMce);

Vue.config.productionTip = false;

ApiConfig.set()
  .then(()=>{
    new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount('#app');
  })
.catch();
