import Vue from 'vue';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const options = {
    position: POSITION.TOP_CENTER,
    transition: 'Vue-Toastification__bounce',
    maxToasts: 5,
    newestOnTop: true
  }

Vue.use(Toast, options);