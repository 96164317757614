import axios  from 'axios';
import { IFormaAcondicionamentoModel } from '~/types/cultura';
const baseURL = '/formaAcondicionamento';

class AcondicionamentoService {

  async getByUndId (id: string): Promise<IFormaAcondicionamentoModel> { 
    return axios.get(`${baseURL}?unidadeMedidaId=${id}`).then(({data}) => data.data);
  }
  
  async getAll (): Promise<Array<IFormaAcondicionamentoModel>> {
    return  axios.get(`${baseURL}/obter-todas`).then(({data}) => data.data);
  } 

  async save (form:IFormaAcondicionamentoModel, acondicionamentoId?:string) {
    if (acondicionamentoId) {
      return axios.put(`${baseURL}/${acondicionamentoId}`, form).then(({data}) => data.data);
    }
    return axios.post(baseURL, form).then(({data}) => data.data);
  }
}

export default new AcondicionamentoService();