class WebStorage {
    constructor() {
        this.localStorage = window.localStorage;
    }

    getItem(key) {
        const content = this.localStorage.getItem(key);
        return content ? JSON.parse(content) : null;
    }

    setItem(key, content) {
        this.localStorage.setItem(key, JSON.stringify(content));
    }

    removeItem(key) {
        this.localStorage.removeItem(key);
    }

    clean () {
        this.localStorage.clear()
    }
}

export default new WebStorage();