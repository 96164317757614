
<template>
  <div class="create-company">
    <div class="create-company__head">
      <RouterLink to="/empresas" class="p1">
        <IconArrow />Contatantes
      </RouterLink>

      <h2>{{ this.Id ? "Editar" : "Novo" }} contatante</h2>
      <el-tag v-if="form.empresaDemonstracao">Conta Demonstração</el-tag>
    </div>

    <div class="create-company__body" id="identificacao">
      <form class="create-company__form" @submit.prevent="submit">
        <div>
          <Label label="Identificação do Contratante" />

          <!-- Informações básicas do cliente - CPF -->
          <div class="create-company__form-group">
            <div class="create-company__form-item">
              <int-input
                label="Razão Social"
                v-model="form.razaoSocial"
                placeholder="Razão Social"
              />           
            </div>
            <div class="create-company__form-item is-multi">
              <int-input
                label="Nome Fantasia"
                v-model="form.nomeFantasia"
                placeholder="Nome Fantasia"
                maxlength="100"
              />
              <int-input
                v-if="Id"
                label="Data de cadastro"
                v-model="form.dataCadastro"
                readonly
                isDate
              />
            </div>

            <div class="create-company__form-item is-multi">
              <InputDocument
                label="CNPJ"
                v-model="form.cnpj"
                inputType="CNPJ"
              />

              <int-input
                filterable
                label="Segmento"
                v-model="form.segmento"
                placeholder="Selecione o segmento"
                isSelect
              >
                <el-option
                  v-for="item in segmentos"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </int-input>
            </div>

            <div class="create-company__form-item is-multi">
              <int-input
                label="Número de unidades"
                v-model="form.numeroUnidades"
                type="numeric"
              />

              <int-input
                label="Número de usuários"
                v-model="form.numeroUsuarios"
                type="numeric"
              />
            </div>
            <div class="create-company__form-item is-multi" v-if="this.Id">
              <int-input
                label="Usuário Master"
                v-model="form.usuarioMaster.nome"
                readonly
              />

              <el-button
                type="terciary"
                @click.prevent="() => (newMasterUser = true)"
                style="margin-left: 20px"
              >
                {{ this.form.usuarioMaster.id ? "Editar" : "Cadastrar" }}
                Usuário
              </el-button>
            </div>
            <div class="create-user__form-item">
              <int-input
                inputType="textarea"
                v-model="form.observacao"
                label="Observações"
              />
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="create-company__form-integration">
                <div>
                  <p>A contratante utilizará a integração com Assinei?</p>                 
                </div>
                <div>
                  <el-radio-group v-model="form.integracaoAssinei">
                    <el-radio :label="true">Sim </el-radio>
                    <el-radio :label="false">Não </el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-col>       
          </el-row>

           <el-row :gutter="20">
            <el-col :span="24">
              <int-input
                label="Tenant Aliare/Assinei"
                v-model="form.tenantAssinei"
                placeholder="Tenant para integração"
                maxlength="100"
              />
            </el-col>
          </el-row>
        </div>

        <el-row :gutter="20">
          <el-col :span="12">
            <div class="create-company__form-type">
              <p class="p2">Status:</p>

              <el-radio-group v-model="form.status">
                <el-radio label="A">Ativo</el-radio>
                <el-radio label="I">Inativo</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="create-company__form-type">
              <p class="p2" style="margin-right: 10px;">Demonstração: </p>
              <el-switch 
                v-model="form.empresaDemonstracao"
              />
            </div>
          </el-col>
        </el-row>

        <div class="create-company__form-actions">
          <el-button type="terciary" @click.prevent="back">
            Cancelar
          </el-button>

          <el-button type="primary" native-type="submit" :loading="loading"> Salvar </el-button>
        </div>
      </form>
    </div>

    <int-modal :show="newMasterUser" :closeFunction="clearUser">
      <div class="modal-feedback__message">
        <h3>
          {{ this.form.usuarioMaster.id ? "Editar" : "Cadastrar" }} usuário
          master
        </h3>
      </div>
      <div class="modal-feedback">
        <form>
          <el-row :gutter="20">
            <el-col :span="24">
              <int-input
                label="Nome"
                v-model="user.nome"
                placeholder="Nome completo"
                maxlength="100"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <int-input
                label="E-mail"
                v-model="user.email"
                placeholder="exemplo@email.com.br"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <int-input
                label="Telefone"
                v-model="user.telefone"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </el-col>
            <el-col :span="12">
              <int-input label="Cargo" v-model="user.cargo" />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <int-input
                inputType="textarea"
                v-model="user.observacao"
                label="Observações"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="create-company__form-type">
                <p class="p2">Status:</p>
                <el-radio-group v-model="user.status">
                  <el-radio label="A">Ativo</el-radio>
                  <el-radio label="I">Inativo</el-radio>
                </el-radio-group>
              </div>
            </el-col>
          </el-row>
        </form>
        <div class="modal-feedback__actions">
          <el-button @click.prevent="clearUser"> Cancelar </el-button>
          <el-button type="primary" @click.prevent="handleNewUserClick">
            {{ this.form.usuarioMaster.id ? "Editar" : "Cadastrar" }}
          </el-button>
        </div>
      </div>
    </int-modal>

    <int-modal :show="feedback">
      <div class="modal-feedback">
        <div class="modal-feedback__message">
          <span>
            <IconCheck />
          </span>
          <h2>
            {{
              this.form.usuarioMaster.id
                ? "Contatante editado com sucesso!"
                : "Contatante cadastrado com sucesso!"
            }}
          </h2>
        </div>

        <div class="modal-feedback__actions">
          <el-button
            :type="this.Id ? 'primary' : 'terciary'"
            @click.prevent="back"
          >
            Ok
          </el-button>
          <el-button
            v-if="!this.form.usuarioMaster.id"
            type="primary"
            @click.prevent="
              () => {
                newMasterUser = true;
                feedback = false;
              }
            "
          >
            Cadastrar usuário master
          </el-button>
        </div>
      </div>
    </int-modal>
    <IntModal :show="feedbackError" :draft-analyse="true">
      <template v-slot:head>
        <div class="modal__custom-head">
          <IconDanger />
          <h2 class="modal__custom-head__danger">Atenção!</h2>
        </div>
      </template>
      <template v-slot:body>
        <div class="modal__custom-body">
          <p class="modal__custom-body__p2">
            {{ errors.submit }}
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="modal__custom-footer">
          <el-button @click="feedbackError = false" type="danger"
            >Retornar
          </el-button>
        </div>
      </template>
    </IntModal>
  </div>
</template>


<script>
import { mapMutations } from 'vuex';
import companyService from '../../../services/companyService';
import userService from '../../../services/userService';
import Label from '../../../components/Label.vue';
import IntModal from '../../../components/Modal.vue';
import IntInput from '../../../components/Input.vue';
import InputDocument from '../../../components/InputDocument.vue';
import IconCheck from '../../../assets/svgs/icon-checked.svg';
import IconArrow from '../../../assets/svgs/icon-arrow-left.svg';
import IconDanger from '../../../assets/svgs/icon-danger.svg';

export default {
  components: {
    Label,
    IntModal,
    IntInput,
    IconDanger,
    IconCheck,
    IconArrow,
    InputDocument,
  },

  data: () => ({
    newCompany: {},
    Id: null,
    loading: false,
    form: {
      nomeFantasia: '',
      razaoSocial: '',
      cnpj: '',
      segmento: '',
      numeroUnidades: '',
      numeroUsuarios: '',
      empresaDemonstracao: false,
      dataCadastro: '',
      status: 'A',
      observacao: '',
      tenantAssinei: '',
      usuarioMaster: {
        id: '',
        nome: '',
      },
      integracaoAssinei:'s'
    },
    user: {
      nome: '',
      email: '',
      telefone: '',
      cargo: '',
      regional: '',
      tipo: 'Aprovador',
      observacao: '',
      admin: true,
      status: 'A',
    },
    feedback: false,
    newMasterUser: false,
    feedbackError: false,
    feedbackDelete: false,
    errors: {
      submit: '',
    },
  }),

  methods: {
    ...mapMutations(['SET_COMPANY']),
    back() {
      this.$router.go(-1);
    },
    async handleNewUserClick() {
      try {      
        const result  = ((await userService.save(this.user, this.form.usuarioMasterId,this.Id)).data).data  
        this.form = { ...this.form, usuarioMaster: { id: result.usuarioId, nome: result.nome}
        };

        try {         
          await companyService.save(this.form, this.Id, this.form.usuarioMaster.id);
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
        this.feedbackError = true;
        this.errors.submit = 'Email existente em outro cadastro, verifique os dados e tente novamente.';
        return;
      }

      this.newMasterUser = false;
    },
    clear() {
      /**
       * @todo clear data
       */
      this.feedback = false;
      this.form = {
        nomeFantasia: '',
        razaoSocial: '',
        cnpj: '',
        segmento: '',
        numeroUnidades: '',
        numeroUsuarios: '',
        status: 'A',
        observacao: '',
        usuarioMaster: {
          id: '',
          nome: '',
        },
      };
      this.Id = null;
      this.clearUser();
    },

    clearUser() {
      this.newMasterUser = false;
      if (this.form.usuarioMaster.id.length === 0) {
        this.user = {
          nome: '',
          email: '',
          telefone: '',
          cargo: '',
          regional: '',
          tipo: 'Aprovador',
          observacao: '',
          admin: true,
          status: 'A',
        };
      }
    },

    async submit() {
      try {
        this.loading = !this.loading;
        const payload = {
          ...this.form,
          dataCadastro: this.Id ? this.form.dataCadastro : undefined,
        }

        this.newCompany = await companyService.save(
          payload,
          this.Id,
          this.form.usuarioMaster.id,
        );
        this.form.dataCadastro = this.newCompany.dataCadastro;  
        this.Id = this.newCompany.empresaId;


        this.feedback = true;
        return;
      } catch (err) {
        console.log(err);
        this.feedbackError = true;
        this.errors.submit = 'CNPJ existente em outro cadastro, verifique os dados e tente novamente.';
      } finally {
        this.loading = !this.loading;
      }
    },
  },
  async mounted() {
    const { empresaId } = this.$route.params;
    try {
      const company = await companyService.getById(empresaId).then((empresa) => empresa[0]);     
      console.log(company)
  
    if (company) {  
      const user = await userService.getAdminById(company.usuarioMasterId).then((usuario) => usuario);
      this.form = {
        ...company,
        usuarioMaster: user
          ? { id: user.usuarioId, nome: user.nome }
          : { id: '', nome: '' },
      };
      if (user) {
        this.user = {
          nome: user.nome,
          email: user.email,
          telefone: user.telefone,
          cargo: user.cargo,
          regional: user.regional,
          tipo: user.tipo,
          observacao: user.observacao,
          admin: user.admin,
          status: user.status,
        };
      }
      this.Id = empresaId;
      }
    } catch (error) {
      const msgm = e.errors.find((m) => m) ?? 'Houve um problema ao buscar os dados!'
      this.$showNotify('error', msgm);
    }
  },

  computed: {
    segmentos() {
      return [
        {
          value: 'Revenda de insumos',
          label: 'Revenda de insumos',
        },
        {
          value: 'Defensivos',
          label: 'Defensivos',
        },
        {
          value: 'Fertilizantes básicos',
          label: 'Fertilizantes básicos',
        },
        {
          value: 'Fertilizantes especiais',
          label: 'Fertilizantes especiais',
        },
        {
          value: 'Sementes',
          label: 'Sementes',
        },
        {
          value: 'Bancos',
          label: 'Bancos',
        },
        {
          value: 'Cooperativas de Crédito',
          label: 'Cooperativas de Crédito',
        },
        {
          value: 'Tradings',
          label: 'Tradings',
        },
        {
          value: 'Fundo de Investimento',
          label: 'Fundo de Investimento',
        },
        {
          value: 'Securitizadora',
          label: 'Securitizadora',
        },
        {
          value: 'Cooperativa agrícola',
          label: 'Cooperativa agrícola',
        },
        {
          value: 'Outros',
          label: 'Outros',
        },
      ];
    },  
    
  },
};
</script>

<style lang="scss">
.create-company {
  &__head {
    display: flex;
    flex-direction: column;

    a {
      color: $--color-gray-6;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h2 {
      color: $--color-gray-7;
      margin-top: 4px;
    }
  }

  &__body {
    display: flex;
    margin-top: 10px;
  }

  &__form {
    width: 100%;
    margin: 20px 0 0 0;

    &-type {
      display: flex;
      margin-top: 10px;
      align-items: center;
      min-height: 28px;

      p {
        color: $--color-gray-6;
      }  

      .el-radio-group {
        margin-left: 30px;

      }      
    
    }

    &-group {
      margin-top: 48px;

      .upload {
        margin-top: 28px;
      }
    }

    &-item {
      &.is-multi {
        display: flex;
        align-items: flex-end;

        .group-input {
          width: 100%;

          & > .input:not(:first-child) {
            margin-top: 24px;
          }
        }

        & > .input:not(:first-child) {
          margin-left: 16px;
        }
      }

      &:not(:first-child) {
        margin-top: 28px;
      }
    }

    &-actions {
      display: flex;
      border-top: 1px solid $--color-gray-5;
      margin-top: 32px;
      padding-top: 32px;
      align-items: center;
      justify-content: flex-end;

      .el-button {
        margin-left: 24px !important;
      }
    }

    &-integration{
      display: block;
      font-size: 14px;     
      color: rgb(46, 58, 89);
      margin: 20px 0px 20px 0px;
      
      .el-radio-group{
        margin-left: 15px;
        }           
    }
  }

  .modal-feedback {
    display: flex;
    flex-direction: column;

    &__message {
      display: flex;
      align-items: center;

      span {
        height: 64px;
        display: flex;
        min-width: 64px;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        background-color: $--color-success-light-2;
      }

      h2 {
        color: $--color-success;
        margin-left: 16px;
      }

      h3 {
        color: $--color-gray-7;
        margin-left: 16px;
        position: relative;
        top: -25px;
      }
    }

    &__actions {
      display: flex;
      margin-top: 36px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
