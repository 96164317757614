<script>
import productService from '../../../services/productService';
import undMedidaService from '../../../services/undMedidaService';
import AcondicionamentoService from '../../../services/AcondicionamentoService';
import Label from '../../../components/Label.vue';
import IntInput from '../../../components/Input.vue';
import IconArrow from '../../../assets/svgs/icon-arrow-left.svg';
import IconPlus from '../../../assets/svgs/icon-plus.svg';
import _ from 'lodash';

export default {
  components: {
    Label,
    IntInput,
    IconArrow,
    IconPlus
  },

  data: () => ({
    pluginOptions: '',
    culturaId: null,
    form: {
      descricao: '',
      status: 'A',
      observacao: '',
    },
    listUndMedida: [],
    listFormaAcondicionamento: [],
    formaAcondicionamento: {
      unidadeMedidaId: '',
      acondicionamento: '',
      fatorDivisor: '',
      ativo: true,
    },
    rules: {
      acondicionamento: [
        { type: 'string', required: true, message: 'Campo obrigatório!', trigger: 'blur' },
        { min: 4, max: 20, message: 'Tamanho permitido entre 4 a 20 caracteres', trigger: 'blur' }
      ],
      fatorDivisor: [
        { type: 'string', required: true, message: 'Campo obrigatório!', trigger: 'blur' },
      ],
      unidadeMedidaId: [
        { type: 'string', required: true, message: 'Campo obrigatório!', trigger: 'blur' },
      ],
    },
    unidadesMedida:[{
      id:'',
      produtoId:'',
      unidade:'',
      formasAcondicionamento:[{}]
    }],

  }),

  methods: {
    back() {
      this.$router.go(-1);
    },

    async deleteItem() {
      await productService.deleteById(this.culturaId);
      this.$router.go(-1);
    },

    clear() {
      this.formaAcondicionamento = {
        unidadeMedidaId: '',
        acondicionamento: '',
        fatorDivisor: '',
        ativo: true,
      }
    },

    async submit() {
      try {
        await productService.save(
          { ...this.form, fatorDivisor: parseFloat(this.form.fatorDivisor) },
          this.culturaId
        );

        this.$showNotify('success', `Cultura ${this.culturaId ? 'editada' : 'criada'} com sucesso!`);
      } catch (error) {
        this.$showNotify('error', `Não foi possível ${this.culturaId ? 'editar' : 'criar'} a cultura`);
      }
    },

    async submitValidateForm() {
      this.$refs['formaAcondicionamento'].validate(async (valid) => { 
        if (valid) {
          return await this.addAcondicionamento();
        } 
        return false;
      });
      
    },

    async addAcondicionamento(){
      try {
        await AcondicionamentoService.save({
          ...this.formaAcondicionamento,
          fatorDivisor: parseFloat(this.formaAcondicionamento.fatorDivisor),
        })
        this.clear();
        this.$showNotify('success', 'Forma de acondicionamento cadastrada com sucesso!');
        await this.setUndMedidaCultura(this.culturaId);
      } catch (e) {
        const msgm = e.errors.find((m) => m) ?? 'Houve um problema ao cadastrar a forma de acondicionamento!'
        this.$showNotify('error', msgm);
      }
    },

    async updateFormaAcondicionamento(obj, idx, status){
      const payload = {
        id: obj.id,
        unidadeMedidaId: obj.unidadeMedidaId,
        acondicionamento: obj.acondicionamento,
        fatorDivisor: obj.fatorDivisor,
        ativo: obj.ativo
      }
      try {
        await AcondicionamentoService.save(payload, payload.id);
        status ?
        this.$showNotify('success', 'A forma de acondicinamento ' +
          `${payload.acondicionamento} foi ` +
          `${payload.ativo ? 'ativada' : 'inativada'}`
        )
        : this.$showNotify('success', 
          `A forma de acondicinamento teve seu fator divisor atualizado para ${payload.fatorDivisor}`,
          `${payload.acondicionamento}`
        )

      } catch (error) {
        const msgm = e.errors.find((m) => m) ?? 'Houve um problema ao atualizar a forma de acondicionamento!'
        this.$showNotify('error', msgm);
        status ? this.filteredAcondicionamento[idx].ativo = !this.filteredAcondicionamento[idx].ativo : ''
      }
    },
    
    async setUndMedidaCultura(produtoId){
      try {
        const product = await (productService
          .getById(produtoId)
          .then((produto) => produto)
        );
        if (product) {
          this.form = {
            ...product,
            fatorDivisor: parseFloat(product.fatorDivisor) * 100,
          };
          this.culturaId = produtoId;
          this.unidadesMedida = product.unidadesMedida?.map((un) => un);
        }
      } catch (error) {
        this.$showNotify('error', 'Houve um erro ao buscar os dados da cultura!');
      }

    },
  },

  async mounted() {
    const { produtoId } = this.$route.params;
    await this.setUndMedidaCultura(produtoId);
    this.listUndMedida = await undMedidaService.getByProdutoId(produtoId);
  },

  computed: {
    acondicionamentoDesc: {
      get() {
        return _.toUpper(this.formaAcondicionamento.acondicionamento);
      },
      set(newValue) {
        this.formaAcondicionamento.acondicionamento = _.toUpper(newValue);
      },
    },
    head() {
      return [
        {
          prop: 'unidadeMedida',
          label: 'UND. DE MEDIDA',
          align: '',
          width: 250
        },
        {
          prop: 'acondicionamento',
          label: 'ACONDICIONAMENTO',
          align: '',
          width: 250
        },
      ];
    },
    filteredAcondicionamento() {
      const items = this.unidadesMedida.flatMap((und) => {
        return und.formasAcondicionamento.map( fAcon => {
          return {
            id: fAcon.id,
            unidadeMedida: und.unidade,
            unidadeMedidaId: und.id,
            acondicionamento: fAcon.acondicionamento,
            fatorDivisor: fAcon.fatorDivisor,
            ativo: fAcon.ativo
          }
        })
      })
      return items;
    },
  },
};
</script>

<template>
  <div class="create-product">
    <div class="create-product__head">
      <RouterLink to="/produtos" class="p1"> <IconArrow />Culturas </RouterLink>
      <h2>{{ this.culturaId ? "Editar" : "Nova" }} cultura</h2>
    </div>

    <div class="create-product__body">
      <div class="create-product__form">
        <div class="create-product__form-identificacao">
          <Label label="Identificação da cultura"/>
          <div class="create-product__form-group">
            <int-input
              label="Nome da Cultura"
              v-model.lazy="form.descricao"
              placeholder="Nome da Cultura"
              maxlength="100"
              @change="submit"
            />
          </div>
          <int-input
            inputType="textarea"
            v-model.lazy="form.observacao"
            @change="submit"
            label="Observações"
          />
          <div class="create-product__form-type">
            <p class="p2">Status:</p>
            <el-radio-group 
              v-model="form.status" 
              @change="submit"
            >
              <el-radio label="A">Ativo</el-radio>
              <el-radio label="I">Inativo</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="create-product__form-acondicionamento">
          <Label label="Formas de Acondicionamento"/>
          <el-form
            class="form-formaAcondicionamento"
            :model="formaAcondicionamento"
            ref="formaAcondicionamento"
            :rules="rules"
          >
            <el-form-item prop="unidadeMedidaId" class="content-min-width">
              <int-input
                label="Unidade de Medida"
                v-model="formaAcondicionamento.unidadeMedidaId"
                placeholder="Unidade"
                isSelect
                filterable
              >
                <el-option
                  v-for="item in listUndMedida"
                  :key="item.id"
                  :label="item.unidade"
                  :value="item.id"
                >
                  <span class="el-select__option-child">{{ item.unidade }}</span>
                </el-option>
              </int-input>
            </el-form-item>
            <el-form-item prop="acondicionamento" class="content-min-width">
              <int-input
                label="Forma de Acondicionamento"
                v-model="acondicionamentoDesc"
                placeholder="Acondicionamento"
              />
            </el-form-item>
            <el-form-item prop="fatorDivisor" class="content-min-width">
              <int-input
                v-model="formaAcondicionamento.fatorDivisor"
                label="Fator divisor"
                placeholder="0,00"
                v-currency="pluginOptions"
                :precision="2"
              />
            </el-form-item>
            <el-form-item>
              <button class="input__icon icon-button" 
                @click.prevent="submitValidateForm"
              >
                <IconPlus/>
              </button
              >
            </el-form-item>
          </el-form>
        </div>  
      </div>
      <div class="table" v-if="unidadesMedida.length > 0">
        <el-table
          header-cell-class-name="team__header"
          :data="filteredAcondicionamento"
        >
          <el-table-column
            v-for="item in head"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            :align="item.align"
          />
        <el-table-column :width="200" align="center" label="FATOR DIVISOR" prop="fatorDivisor">
            <template v-slot="scope">
              <el-input
                type="number"
                v-model.lazy="filteredAcondicionamento[scope.$index].fatorDivisor"
                @change="updateFormaAcondicionamento(filteredAcondicionamento[scope.$index], scope.$index, false)"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column :min-width="250" align="center" label="STATUS" prop="ativo">
            <template v-slot="scope">
              <el-switch 
                v-model="filteredAcondicionamento[scope.$index].ativo"
                @change="updateFormaAcondicionamento(filteredAcondicionamento[scope.$index], scope.$index, true)"
                inactive-color="#FD4C4C"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-product {
  &__head {
    display: flex;
    flex-direction: column;
    margin-bottom: -60px;

    a {
      color: $--color-gray-6;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h2 {
      color: $--color-gray-7;
      margin-top: 4px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;

    & .form-formaAcondicionamento{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 100%;

      & .el-form-item {
        padding-right: 5px;

        &.content-min-width{
          min-width: 300px;
        }
      }

    }
  }

  &__form {
    width: 100%;
    margin: 100px 0 0 0;

    .label{
      margin-bottom: 25px;
    }

    &-acondicionamento{
      margin-top: 50px;
      margin-bottom: 25px;
    }

    &-type {
      display: flex;
      margin-top: 10px;
      align-items: center;

      p {
        color: $--color-gray-6;
      }

      .el-radio-group {
        margin-left: 30px;
      }

    }

    &-item {
      &.is-multi {
        display: flex;
        align-items: flex-end;

        .group-input {
          width: 100%;

          & > .input:not(:first-child) {
            margin-top: 24px;
          }
        }

        & > .input:not(:first-child) {
          margin-left: 16px;
        }
      }

      &:not(:first-child) {
        margin-top: 28px;
      }
    }

  }

  .table{
    width: 100%;
    margin: 15px 0 15px 0;
  }
}
</style>
