import Notification from '../components/Notification.vue';
import Vue from 'vue';
import { MessageType } from '../types/types';
import { TYPE } from 'vue-toastification';
import _ from 'lodash';

export const configToastification = (message:string, titulo?:string) => {
  
  return {
    component: Notification,
    props: {
      message: `${message}`,
      titulo: `${titulo}`
    }
  }
}
/**
* Exibe uma notificação ao usuário, sendo um erro, atenção ou sucesso.
* @param tipo Passar o parâmetro para erros tratado no front: 'WARNING', que a API lançou: 'ERROR' e sucesso na ação: 'SUCCESS'.
* @param titulo Título da mensagem.
* @param message Mensagem que será exibida ao usuário.
*/
export const showNotify = (tipo: MessageType, message: string, title?: string) => {
  if(_.isEmpty(title)) title = '';
  Vue.$toast(configToastification(message, title), {
    type: tipo as TYPE  // or "success", "error", "default", "info" and "warning"
  });
}

export default showNotify;
