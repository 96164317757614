
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name:'Notification'
})
export default class Notification extends Vue{  
  @Prop({default: '', required: true}) titulo!:string | undefined;
  @Prop({default: '', required: true}) message!:string;

  get hasTitulo(){
    return this.titulo;
  }
}
