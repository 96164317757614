import webStorage from '../../common/webStorage'
import authService from '../../services/authService'

export default {
    namespaced: true,

    state: {
        usuario: webStorage.getItem('usuario') || {},
        token: webStorage.getItem('token') || '',
    },

    getters: {
        usuario: (state) => state.usuario,
        accessToken: (state) => state.token,
        loginToken: (state) => state.token ? true : false 
    },
    mutations: {
        setUsuario(state, usuario) {
            state.usuario = usuario
            webStorage.setItem('usuario', usuario)
        },
        setToken(state, token) {
            state.token = token
            if(token) {
                webStorage.setItem('token', token)
            } else {
                webStorage.removeItem('token')
            }
        }
    },
    actions: {
        async authenticateUser({ commit }, userCredentials) {
            const { email, password } = userCredentials
            
            try {
                const usuario = (await authService.login({ email, password }))
                if (usuario.token) {
                    commit('setUsuario', usuario)
                    commit('setToken', usuario.token)
                    return true
                }
              
            } catch {
              throw 'Erro ao realizar autenticação via usuário e senha.'
            } finally {
              //
            }
      
            return false
        },
        revokeToken({commit}) {
            commit('setUsuario', null)
            commit('setToken', null)
            authService.logout()
        },
    }
}