import axios from 'axios'
import baseService from './baseServise';
const baseURL = '/produto';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class productService extends baseService<any> {
  constructor(){
    super(baseURL);
  }

  async save (product, productId) {
    if (productId) {
      return axios.put(`${baseURL}/${productId}`, product).then((res) => res);
    }
    return axios.post(`${baseURL}`, product).then((res) => res);
  }
}

export default new productService();
